import React from "react";
import { graphql } from "gatsby";
import useScript from "../hooks/useScript";

import Layout from "../components/layout";

import IconCall from "../images/icons/theme/communication/call-1.svg";
import IconMail from "../images/icons/theme/communication/send.svg";

import LogoSamsung from "../images/logos/brand/grey-samsung.svg";
import LogoRedbull from "../images/logos/brand/grey-hubspot.svg";
import LogoHubspot from "../images/logos/brand/grey-red-bull.svg";
import LogoTmobile from "../images/logos/brand/grey-t-mobile.svg";
import IconCheck from "../images/icons/interface/check.svg";
import { CareersJsonLd } from "../components/jsonld/CareersJsonld";

const CareerPage = ({ data, location: { pathname } }) => (
  <Layout
    headerBgClass="bg-primary-alt"
    footerBgClass="bg-white"
    meta={{ seo: data.datoCmsPage.seo, path: pathname }}
    className="bg-primary-alt"
  >
    <CareersJsonLd />
    <div className="contact-us-page bg-primary-alt">
      <div className="contact-us-page__bg">
        {/*<div className="divider">
          <Divider3 style={{ transform: 'matrix(-1, 0, 0, 1, 0, 0)' }} />
        </div>*/}
      </div>

      <section className="">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 mb-6">
              <h1 className="">Join Our Team!</h1>
              <p className="lead">
                We're looking for talented people to join our core team and grow
                with us. 
                {/* <br/><a href="https://tally.so/r/3qVrP9" target="_blank">All our openings</a> */}
              </p>


              <div className="col-12 mt-6">
                <a
                  href="https://tally.so/r/mJ8Reo"
                  target="_blank"
                  className="card card--common card--capabilities card--blog--vertical card-body justify-content-between"
                >
                  <h4>Senior Web / Product Designer - FT</h4>
                  <span className="badge badge-primary mb-2 col-6 col-md-2">
                    Remote
                  </span>
                </a>

             {/*    <a
                  href="/ui-ux-designer-job"
                  className="card card--common card--capabilities card--blog--vertical card-body justify-content-between"
                >
                  <h4>UX / UI Designer - FT</h4>
                  <span className="badge badge-primary mb-2 col-6 col-md-2">
                    Remote
                  </span>
                </a>

                <a
                  href="https://adamfard.freshteam.com/jobs"
                  className="card card--common card--capabilities card--blog--vertical card-body justify-content-between"
                >
                  <h4>Business Development Representative - FT</h4>
                  <span className="badge badge-primary mb-2 col-6 col-md-2">
                    Remote
                  </span>
                </a>

                <a
                  href="/junior-ui-ux-designer-job"
                  className="card card--common card--capabilities card--blog--vertical card-body justify-content-between"
                >
                  <h4>Junior UX / UI Designer - FT</h4>
                  <span className="badge badge-primary mb-2 col-6 col-md-2">
                    Remote
                  </span>
                </a> */}

                {/* <a
                  href="/junior-ux-researcher"
                  className="card card--common card--capabilities card--blog--vertical card-body justify-content-between"
                >
                  <h4>Junior UX Researcher - FT</h4>
                  <span className="badge badge-primary mb-2 col-6 col-md-2">
                    Remote
                  </span>
                </a> */}

              
   
                
              </div>

              {/* <div className="col-12">
                <a
                  href="/ui-ux-designer-job-de"
                  className="card card--common card--capabilities card--blog--vertical card-body justify-content-between"
                >
                  <h4>Produktdesigner - UI UX Designer (w/m/d) – deutschsprachig</h4>
                  <span className="badge badge-primary mb-2 col-6 col-md-2">
                    Home-Office
                  </span>
                </a>
              </div> */}
              

              {/* <div className="col-12 opacity-50">
                <a
                  href="/outreach-community-manager"
                  className="card card--common card--capabilities card--blog--vertical card-body justify-content-between"
                >
                  <h4>Outreach & Community Manager - FT - Closed</h4>
                  <span className="badge badge-primary mb-2 col-6 col-md-2">
                    Remote
                  </span>
                </a>

                <a
                  href="/ux-researcher"
                  className="card card--common card--capabilities card--blog--vertical card-body justify-content-between"
                >
                  <h4>UX Researcher - Closed</h4>
                  <span className="badge badge-primary mb-2 col-6 col-md-2">
                    Remote
                  </span>
                </a>
              </div> */}

             

              {/* <div className="col-12 opacity-50">
                <a
                  href="/business-development-sales"
                  className="card card--common card--capabilities card--blog--vertical card-body justify-content-between"
                >
                  <h4>Business Development Manager - Closed</h4>
                  <span className="badge badge-primary mb-2 col-6 col-md-2">
                    Remote
                  </span>
                </a>
              </div> */}

              {/* <div className="col-12">
                <a
                  href="/graphic-designer"
                  className="card card--common card--capabilities card--blog--vertical card-body justify-content-between"
                >
                  <h4>Graphic Designer - 100% Remote</h4>
                  <span className="badge badge-primary mb-2 col-6 col-md-2">Remote</span>
                </a>
              </div> */}

              {/* <div className="col-12 opacity-50">
                <a
                  href="/marketing-manager"
                  className="card card--common card--capabilities card--blog--vertical card-body justify-content-between"
                >
                  <h4>Head of Marketing - Closed</h4>
                  <span className="badge badge-primary mb-2 col-6 col-md-2">
                    Remote
                  </span>
                </a>
              </div> */}

              {/* <h2 className="mt-6">Your Benefits:</h2>
              <ul className="lead">
                <li>100% Remote</li>
                <li>No micromanagement</li>
                <li>Balanced workload</li>
                <li>Access to our Talent Development Program</li>
                <li>B2B Contract</li>
              </ul> */}

              {/* <h2 className="mt-6">Have a different role in mind?</h2>
              <a href="mailto:careers@adamfard.com" className="lead hover-arrow">
                Get in touch
              </a> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default CareerPage;

export const query = graphql`
  query CareerPage {
    datoCmsPage(slug: { eq: "careers" }) {
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
    }
  }
`;
