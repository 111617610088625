import React from "react";
import { Link } from "gatsby";

import Logo from "../images/logo.svg";
import LogoWhite from "../images/logo-white.svg";

const Footer = ({ footerBgClass = "bg-primary-alt" }) => (
  <footer id="footer" className={footerBgClass}>
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6 order-last order-lg-first nav-social">
          <a href="https://adamfard.com" aria-label="Adama Fard home page">
            {footerBgClass === "bg-primary-3" ? <LogoWhite /> : <Logo />}
          </a>

          <div>
            <p>Connect with us on</p>
            <ul className="nav">
              <li>
                <a
                  href="https://www.linkedin.com/company/adam-fard/"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  LinkedIn
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UC8sQAC8xQv9UiG5HEGckqIA"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  Youtube
                </a>
                
              </li>
              <li>
                <a
                  href="https://www.behance.net/AdamFardStudio"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  Behance
                </a>
              </li>
              <li>
                <a
                  href="https://www.tiktok.com/@adamfard"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  TikTok
                </a>
              </li>
              
              {/* <li>
                <a
                  href="https://www.facebook.com/AdamFardInsights/"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  Facebook
                </a>
              </li> */}
            </ul>
          </div>

          <div className="nav-bottom">
            <span>©{new Date().getFullYear()} All Rights Reserved.</span>
            <Link to="/privacy-policy">Privacy Policy</Link>
            {/*<Link to="/terms-of-service">Terms of Service</Link>*/}
          </div>
        </div>

        <div className="col-12 col-lg-2 nav-inner">
          <ul className="nav flex-column">
            <li className="nav-item align-items-center mb-2 opacity-30">
              <strong>Solutions</strong>
            </li>
            <li className="nav-item">
              <Link to="/fintech-design">Fintech Design</Link>
            </li>
            <li className="nav-item">
              <Link to="/ai-design-agency">AI Design</Link>
            </li>
            <li className="nav-item">
              <Link to="/saas-design">SaaS Design</Link>
            </li>
            <li className="nav-item">
              <Link to="/edtech-design">Edtech Design</Link>
            </li>
            <li className="nav-item">
              <Link to="/ux-consulting">UX Consulting</Link>
            </li>
            <li className="nav-item">
              <Link to="/ux-ui-design-services">UX Services</Link>
            </li>
            <li className="nav-item">
              <Link to="/unlimited-ux-ui-design-subscription">UX Design Subscription</Link>
            </li>
            {/* <li className="nav-item">
              <Link to="/ux-design-process">UX Design Process</Link>
            </li> */}
          </ul>
        </div>

        <div className="col-12 col-lg-2 nav-inner">
          <ul className="nav flex-column">
            <li className="nav-item align-items-center mb-2 opacity-30">
              <strong>UX Agency</strong>
            </li>
            <li className="nav-item">
              <Link to="/ux-projects">UX Projects</Link>
            </li>
            <li className="nav-item">
              <Link to="/ux-design-process">UX Design Process</Link>
            </li>
            <li className="nav-item">
              <Link to="/about-us">About Us</Link>
            </li>
            {/*<li className="nav-item">
              <Link to="/ux-resources">UX Resources</Link>
            </li>*/}
            {/*<li className="nav-item">
              <Link to="/pillar-page">UX Guides</Link>
            </li>*/}
            <li className="nav-item">
              <Link to="/contact-us">Contact Us</Link>
            </li>
          </ul>
          <ul className="nav flex-column mt-3">
            <li className="nav-item align-items-center mb-2 opacity-30">
              <strong>Growth</strong>
            </li>
            <li className="nav-item">
              <Link to="https://fameclub.adamfard.com" target="_blank">Social Media Marketing</Link>
            </li>
           
          </ul>
          <ul className="nav flex-column mt-3">
            <li className="nav-item align-items-center mb-2 opacity-30">
              <strong>Directory</strong>
            </li>
            <li className="nav-item">
              <Link to="/us/agencies/ui-ux" target="_blank">Agencies (US)</Link>
            </li>
            <li className="nav-item">
              <Link to="/uk/agencies/ui-ux" target="_blank">Agencies (UK)</Link>
            </li>
            <li className="nav-item">
              <Link to="/de/agenturen/ui-ux" target="_blank">Agenturen (DE)</Link>
            </li>
           
          </ul>
        </div>

        <div className="col-12 col-lg-2 nav-inner">
          <ul className="nav flex-column">
            <li className="nav-item align-items-center mb-2 opacity-30">
              <strong>Links</strong>
            </li>

            <li className="nav-item align-items-center">
              <Link className="align-items-center" to="/careers">
                Careers {/* <span className="badge badge-primary">hiring</span> */}
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link to="/write-for-us-guest-post">Write for Us</Link>
            </li> */}
            <li className="nav-item">
              <Link to="/blog">UX Blog</Link>
            </li>
            <li className="nav-item">
              <Link to="/download-ux-resources">Downloads</Link>
            </li>
            <li className="nav-item">
              <a href="https://app.adamfard.com/ai-color-generator" target="_blank">AI Color Generator</a>
            </li>
            <li className="nav-item">
              <a href="https://app.adamfard.com/startup-ideas-generator" target="_blank">Startup Ideas Generator</a>
            </li>
            <li className="nav-item">
              <a href="https://uxpilot.ai/ai-ui-generator" target="_blank">AI UI Generator</a>
            </li>
            <li className="nav-item">
              <a href="https://uxpilot.ai/ai-wireframe-generator" target="_blank">AI Wireframe Generator</a>
            </li>
            <li className="nav-item">
              <a href="https://uxpilot.ai/" target="_blank">AI for UX Design</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
